<template>
    <div id="index" :class="deviceInfo.mobile ? 'mobile-index' : ''">
        <Header />
        <!-- <div class="content">
            <Banner />
            <Product :productList="productList" />
            <Teacher />
            <About />
        </div> -->
        <img class="content1" src="https://res.peki.vip/20240125/d7a78992e9d14b0892438aabb0d0a89e.png" />
        <img class="content2" src="https://res.peki.vip/20240125/3b492de4d22d419d9216fddae55e249f.png" />
        <Fotter />
    </div>
</template>
<script>
import Header from '@/components/layout/Header.vue'
import Fotter from '@/components/layout/Fotter.vue'
// import Banner from './components/Banner.vue'
// import Product from './components/Product.vue'
// import About from './components/About.vue'
// import Teacher from './components/Teacher.vue'
import { getRecommend } from '@/api/courseApi.js'
import { mapGetters } from 'vuex'

export default {
    name: 'index',
    components: { Header, Fotter },
    computed: { ...mapGetters(['userInfo', 'deviceInfo']) },
    data() {
        return {
            productList: [],
        }
    },
    created() {
        this.getRecommendApi()
    },
    mounted() {
        if (!this.userInfo && this.$route.query.redirect) {
            this.$store.dispatch('user/showLoginDialog')
        }
    },
    methods: {
        getRecommendApi() {
            getRecommend({ offset: 0, limit: 3 }).then((res) => {
                this.productList = res.data
            })
        },
    },
}
</script>
<style lang="scss" scoped>
#index {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .content {
        flex-grow: 1;
    }
}
.content1,
.content2 {
    width: 100%;
    object-fit: cover;
}

.content1 {
    display: none;
}
.content2 {
    display: block;
    height: calc(100vh - 174px);
}

@media screen and (max-width: 900px) {
    .content1 {
        display: block;
        min-height: calc(100vh - 240px);
    }
    .content2 {
        display: none;
    }
}
</style>
